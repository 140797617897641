export const Domain = "https://gw-qa.prikly.io";

export const getBearerToken = () => {
  return localStorage.getItem("access_token") || null;
};

export const getTokenCom = () => {
  return localStorage.getItem("tokenCom");
};

export const getEmpresa = () => {
  return localStorage.getItem("empresa") || 16;
};

const actualizarAuthorization = () => {
  const Config = {
    headers: {
      Authorization: `Bearer ${getBearerToken()}`,
    },
  };
  return Config;
};

export const getConfig = () => {
  return actualizarAuthorization();
};
