// Vars
const basePath = "https://qa.prikly.io/recursos/icons";

/* CONFIG MENU */
export const ScissorsIcon = `${basePath}/configMenu/scissorsIcon.svg`;

/* LOUNGE */
export const SiteIcon = `${basePath}/lounge/store.svg`;

/* CASH */
export const CashRegisterStrokeIcon = `${basePath}/cash/cashRegisterStroke.svg`;
export const CoinsIcon = `${basePath}/cash/coins.svg`;

/* ORIENTATION */
export const CommonArrowRightIcon = `${basePath}/orientation/commonArrowRight.svg`;
export const arrowLeftIcon = `${basePath}/orientation/arrowLeft.svg`;
export const altArrowLeftIcon = `${basePath}/orientation/altArrowLeft.svg`;
export const altArrowRightIcon = `${basePath}/orientation/altArrowRight.svg`;
export const altDoubleArrowRightIcon = `${basePath}/orientation/altDoubleArrowRight.svg`;
export const altDoubleArrowLeftIcon = `${basePath}/orientation/altDoubleArrowLeft.svg`;

/* PRODUCTS */
export const BoxIcon = `${basePath}/products/box.svg`;
export const FindBox = `${basePath}/products/findBox.svg`;
export const DiscountIcon = `${basePath}/products/discount.svg`;
export const GiftIcon = `${basePath}/products/gift.svg`;
export const OpenBoxIcon = `${basePath}/products/openBox.svg`;

/* SEARCH */
export const MagnifyingGlassIcon = `${basePath}/search/magnifyingGlass.svg`;
export const FilterIcon = `${basePath}/search/filter.svg`;

/* SYSTEM */
export const CircleUserIcon = `${basePath}/system/circleUser.svg`;

/* SYMBOLS */
export const WarningTriangleIcon = `${basePath}/symbols/warningTriangle.svg`;

/* USER ACTIONS */
export const AddTagIcon = `${basePath}/userActions/addTag.svg`;
export const CheckedIcon = `${basePath}/userActions/checked.svg`;
export const UncheckedIcon = `${basePath}/userActions/unchecked.svg`;
export const CurveArrowIcon = `${basePath}/userActions/curveArrow.svg`;
export const PencilIcon = `${basePath}/userActions/edit.svg`;
export const TrashCanIcon = `${basePath}/userActions/trashBin.svg`;
export const TripleDotsIcon = `${basePath}/userActions/tripleDots.svg`;
export const UserInfoIcon = `${basePath}/userActions/info.svg`;
export const AddIcon = `${basePath}/userActions/add.svg`;
export const MinusIcon = `${basePath}/userActions/minus.svg`;

/* OTHERS */
export const Loader = `${basePath}/loader/loadingPulse.svg`;
export const TimesIcon = `${basePath}/timesIcon.svg`;
export const PeopleIcon = `${basePath}/peopleIcon.svg`;
export const HelpIcon = `${basePath}/helpIcon.svg`;
export const viutifyBrand = `${basePath}/brands/viutify.svg`;

//products
export const openBox = `${basePath}/products/openBox.svg`;
export const timesIcon = `${basePath}/userActions/times.svg`;

export const ServiceIcon = `${basePath}/system/service.svg`;
